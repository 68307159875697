var popUp = {
	init: function () {

		var $popUpWindow = $(".video"); 
		var $galleryWindow = $(".gallery"); 
		var $workWindow = $(".work");

		$popUpWindow.click(function(event) {
			$(this).modal({
				fadeDuration: 250,
				fadeDelay: 0.80
			});
			return false;
		});

		$galleryWindow.click(function(event) {
			$(this).modal({
				fadeDuration: 250,
				fadeDelay: 0.80
			});
			return false;
		});

		const myTimeout = setTimeout(function () { $workWindow.modal() }, 10000 );
	}
};

popUp.init();