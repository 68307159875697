var swipe = {
	init: function () {

		var swiperClients = new Swiper('.clients-slide', {
			loop: true,
			autoplay: {
				delay: 5000,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},			
			slidesPerView: 1,
			// Responsive breakpoints
			breakpointsInverse: true,
			breakpoints: {
				// when window width is >= 1024px
				1024: {
				  slidesPerView: 3
				  // spaceBetween: 20
				}
			}	

		});

		var swiperClients = new Swiper('.servicios-slide', {
			loop: true,
			autoplay: {
				delay: 5000,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},			
			slidesPerView: 1,
			// Responsive breakpoints
			breakpointsInverse: true,
			breakpoints: {
				// when window width is >= 1024px
				768: {
				  slidesPerView: 2,
				  spaceBetween: 20
				},
				1280: {
				  slidesPerView: 3,
				  spaceBetween: 20
				}
			}	

		});

		// breakpoint where swiper will be destroyed
		// and switches to a dual-column layout
		const breakpoint = window.matchMedia( '(min-width:1200px)' );
		// keep track of swiper instances to destroy later
		var mySwiper;

		//////////////////////////////////////////////////////////////////
		const breakpointChecker = function() {
		   // if larger viewport and multi-row layout needed
		   if ( breakpoint.matches === true ) {
		      // clean up old instances and inline styles when available
		      if ( mySwiper !== undefined ) mySwiper.destroy( true, true );
		      // or/and do nothing
		      return;
		   // else if a small viewport and single column layout needed
		   } else if ( breakpoint.matches === false ) {
		      // fire small viewport version of swiper
		      return enableSwiper();
		   }
		};

		//////////////////////////////////////////////////////////////////
		const enableSwiper = function() {
		   mySwiper = new Swiper ('.swiper-container.footer_address', {
				loop: true,
				autoplay: {
					delay: 5000,
				},
				lazy: true,
				navigation: {
				    nextEl: '.swiper-button-next',
				  },
				// Default parameters for smallest screen
				slidesPerView: 1,
				spaceBetween: 0,
				// Responsive breakpoints
				breakpointsInverse: true,
				breakpoints: {
					// when window width is >= 1024px
					1024: {
					  slidesPerView: 3
					  // spaceBetween: 20
					}
				}			      
		   });
		};

		// keep an eye on viewport size changes
		breakpoint.addListener(breakpointChecker);
		// kickstart
		breakpointChecker();		
	}
};

swipe.init();